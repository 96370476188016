var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('a',{staticClass:"margin-bottom",staticStyle:{"border":"1px black"},on:{"click":_vm.goBack}},[_c('span',{staticClass:"fa fa-long-arrow-left"}),_vm._v(" Back")]),_c('br'),_c('br'),_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[(_vm.publisherData !== null)?_c('a-form',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Name"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Publisher's name.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
                  initialValue: _vm.publisherData.Name, rules: [
                    { required: true, message: 'Name is required.' },
                    { max: 64, message: 'Maximum 64 characters allowed.' }
                  ]
                }]),expression:"['name', {\n                  initialValue: publisherData.Name, rules: [\n                    { required: true, message: 'Name is required.' },\n                    { max: 64, message: 'Maximum 64 characters allowed.' }\n                  ]\n                }]"}],attrs:{"placeholder":"Publisher Name"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Email"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Publisher's e-mail address.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['email', {
                  initialValue: _vm.publisherData.Mail,
                  rules: [
                    { required: true, message: 'Email is not valid.', pattern: _vm.emailPattern },
                    { max: 64, message: 'Maximum 64 characters allowed.' }
                  ]
                }]),expression:"['email', {\n                  initialValue: publisherData.Mail,\n                  rules: [\n                    { required: true, message: 'Email is not valid.', pattern: emailPattern },\n                    { max: 64, message: 'Maximum 64 characters allowed.' }\n                  ]\n                }]"}],attrs:{"type":"email","placeholder":"Publisher Email Address"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Username"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Publisher's username. It is required for fetching statistics through stats API or CSV export.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['username', { initialValue: _vm.publisherData.Username }]),expression:"['username', { initialValue: publisherData.Username }]"}],attrs:{"disabled":"","type":"text","placeholder":"Publisher Username"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Country"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Publisher's country.")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['country', {
                  initialValue: (_vm.publisherData.Country === '' ? [] : _vm.publisherData.Country),
                  rules: [{ required: true, message: 'Country is required.' }]
                }]),expression:"['country', {\n                  initialValue: (publisherData.Country === '' ? [] : publisherData.Country),\n                  rules: [{ required: true, message: 'Country is required.' }]\n                }]"}],attrs:{"placeholder":"Please select a country","showSearch":true,"filterOption":_vm.countryFilter,"optionFilterProp":"children"}},_vm._l((_vm.info.countries),function(obj){return _c('a-select-option',{key:obj.CC},[_c('img',{attrs:{"src":_vm.matchIcon(_vm.countries, obj.CC)}}),_vm._v(" "+_vm._s(obj.Country))])}),1)],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Description"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Publisher's description.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['description', {
                  initialValue: _vm.publisherData.Description,
                  rules: [
                    { max: 512, message: 'Maximum 512 characters allowed.' }
                  ]
                }]),expression:"['description', {\n                  initialValue: publisherData.Description,\n                  rules: [\n                    { max: 512, message: 'Maximum 512 characters allowed.' }\n                  ]\n                }]"}],attrs:{"placeholder":"Publisher Description"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Website"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Publisher's website.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['website', {
                  initialValue: _vm.publisherData.Website,
                  rules: [
                    { max: 64, message: 'Maximum 64 characters allowed.' }
                  ]
                }]),expression:"['website', {\n                  initialValue: publisherData.Website,\n                  rules: [\n                    { max: 64, message: 'Maximum 64 characters allowed.' }\n                  ]\n                }]"}],attrs:{"placeholder":"Publisher Website"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"City"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Publisher's city.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['city', {
                  initialValue: _vm.publisherData.City,
                  rules: [
                    { max: 20, message: 'Maximum 20 characters allowed.' }
                  ]
                }]),expression:"['city', {\n                  initialValue: publisherData.City,\n                  rules: [\n                    { max: 20, message: 'Maximum 20 characters allowed.' }\n                  ]\n                }]"}],attrs:{"placeholder":"City"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Zip Code"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Publisher's zip code.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['zipCode', {
                  initialValue: _vm.publisherData.ZipCode,
                  rules: [{ transform: function (value) { return Number(value) }, type: 'number' }]
                }]),expression:"['zipCode', {\n                  initialValue: publisherData.ZipCode,\n                  rules: [{ transform: function (value) { return Number(value) }, type: 'number' }]\n                }]"}],attrs:{"type":"number","placeholder":"Zip Code"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Address"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Publisher's address.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['address', {
                  initialValue: _vm.publisherData.Address, rules: [
                    { max: 64, message: 'Maximum 64 characters allowed.' }
                  ]
                }]),expression:"['address', {\n                  initialValue: publisherData.Address, rules: [\n                    { max: 64, message: 'Maximum 64 characters allowed.' }\n                  ]\n                }]"}],attrs:{"placeholder":"Address"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Phone"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Publisher's phone number.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone', {
                  initialValue: _vm.publisherData.Phone,
                  rules: [
                    { message: 'Invalid phone number.' },
                    { max: 20, message: 'Maximum 20 characters allowed.' }
                  ]
                }]),expression:"['phone', {\n                  initialValue: publisherData.Phone,\n                  rules: [\n                    { message: 'Invalid phone number.' },\n                    { max: 20, message: 'Maximum 20 characters allowed.' }\n                  ]\n                }]"}],attrs:{"placeholder":"Phone"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Skype"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Publisher's Skype username.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['skype', {
                  initialValue: _vm.publisherData.Skype,
                  rules: [
                    { max: 32, message: 'Maximum 32 characters allowed.' }
                  ]
                }]),expression:"['skype', {\n                  initialValue: publisherData.Skype,\n                  rules: [\n                    { max: 32, message: 'Maximum 32 characters allowed.' }\n                  ]\n                }]"}],attrs:{"placeholder":"Skype"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Platform Domain"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("The canonical domain name of the advertising system of the publisher. RTB Zone of the publisher will try to inherit this value on creation.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['platformDomain', {
                    rules: [{ max: 32, message: 'Maximum 32 characters allowed.' },
                    { pattern: _vm.domainRegExp, message: '\'www.\', \'http://\' and \'https://\' are not allowed. Please input pure top-level domain, for example: google.com' }], initialValue: _vm.publisherData.PlatformDomain
                  }]),expression:"['platformDomain', {\n                    rules: [{ max: 32, message: 'Maximum 32 characters allowed.' },\n                    { pattern: domainRegExp, message: '\\'www.\\', \\'http://\\' and \\'https://\\' are not allowed. Please input pure top-level domain, for example: google.com' }], initialValue: publisherData.PlatformDomain\n                  }]"}],attrs:{"type":"text","placeholder":"Publisher Platform Domain"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"sellers.json Domain"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("The canonical domain where advertising system of the publisher hosts its sellers.json file. Usually it is the same domain as Platform Domain. RTB Zone of the publisher will try to inherit this value on creation.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sellersJsonDomain', {
                    rules: [{ max: 32, message: 'Maximum 32 characters allowed.' },
                    { pattern: _vm.domainRegExp, message: '\'www.\', \'http://\' and \'https://\' are not allowed. Please input pure top-level domain, for example: google.com' }], initialValue: _vm.publisherData.SellersJsonDomain
                  }]),expression:"['sellersJsonDomain', {\n                    rules: [{ max: 32, message: 'Maximum 32 characters allowed.' },\n                    { pattern: domainRegExp, message: '\\'www.\\', \\'http://\\' and \\'https://\\' are not allowed. Please input pure top-level domain, for example: google.com' }], initialValue: publisherData.SellersJsonDomain\n                  }]"}],attrs:{"type":"text","placeholder":"sellers.json Domain"}})],2)],1),_c('a-divider',{staticClass:"-ml-5"}),_c('div',{staticClass:"ant-row ant-form-item"},[_c('div',{staticClass:"ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"}),_c('div',{staticClass:"ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper"},[_c('button',{staticClass:"btn btn-success px-5",attrs:{"type":"submit"}},[_vm._v("Save Changes")]),_c('button',{class:'btn btn-' + (_vm.publisherData.Status === 0 ? 'danger' : 'primary') + ' px-5 ml-2',attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.togglePublisherStatus.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.publisherData.Status == 0 ? 'Deactivate' : 'Activate')+" Publisher ")]),_c('button',{staticClass:"btn px-5 ml-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'publishers' })}}},[_vm._v(" Cancel ")])])])],1):_vm._e()],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"cui__utils__heading mb-0"},[_c('strong',[_vm._v("Edit Publisher")])]),_c('div',{staticClass:"text-muted"},[_vm._v("Edit publisher information")])])
}]

export { render, staticRenderFns }